.hero.interior-hero {
  border-bottom: 10px solid $white;
  padding: 160px 0 50px;
  @include tablet {
    padding: 200px 0 110px;
  }
  @include laptop {
    padding: 230px 0 135px;
  }
  @include desktop {
    padding: 270px 0 140px;
  }
}


.page-content-panel {
  background-color: $gray;

  h1 {
    margin: 0 0 35px;
  }
}

.alignleft, .alignright, .alignnone {
  margin: 0 0 20px;
  min-width: 100%;

  @include tablet {
    min-width: auto;
  }
}

.alignleft {

  @include tablet {
    margin: 0 20px 5px 0;
  }
}

.alignright {

  @include tablet {
    margin: 0 0 5px 20px;
  }
}


.map-panel {
  border-bottom: 10px solid $gray;
}


.card {
  box-shadow: 0px 0px 4px 2px rgba($black, 0.1);
  border-radius: 0;
  border: none;
}

.staff-col {
  margin-bottom: 25px;
}


.financing-card {
  margin-bottom: 30px;

  .card-header {
    background-color: $blue-dark;
    border-radius: 0;

    h2, p {
      color: $white;
    }

    *:last-child {
      margin-bottom: 0;
    }
  }

  .financing-header {
    margin-bottom: 20px;
  }

  .identity-col {
    margin-bottom: 0;

    h3 {
      margin-top: 0;
    }
  }
}

.financing-table {
  text-align: center;

  th, td {
    text-align: center;

    &:nth-of-type(2) {
      background-color: $red;
      color: $white;
    }
  }
}


.product-list {

  .product-preview-block {
    height: 100%;
    padding-bottom: 30px;

    .card {
      height: 100%;

      .card-body {
        display: flex;
        flex-direction: column;
      }
    }

    .product-preview-image {
      position: relative;
      width: 100%;
      @include aspect-ratio(4,3);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      margin-bottom: 15px;

      &:after {
        font-family: FontAwesome;
        font-size: 24px;
        content: '\f00e';
        @include stretch;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba($black, .5);
        color: $white;
        opacity: 0;
        transition: $base-transition;
      }

      &:hover:after {
        opacity: 1;
      }
    }

    .product-preview-price {
      font-weight: 500;
    }

    .btn {
      width: 100%;
      margin-top: auto;
    }
  }
}
