.hero {
  background-image: url(../images/kitchenhero.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;

  &.hero-home {
    padding: 160px 0 270px;
    @include tablet {
      padding: 240px 0 280px;
    }
    @include laptop {
      padding: 280px 0 350px;
    }
    @include desktop {
      padding: 320px 0 390px;
    }
    @include wide {
      padding: 350px 0 420px;
    }
  }
}

.cta-content {
  .vp-sub {
    font-size: 18px;
    @include tablet {
      font-size: 20px;
    }
    @include desktop {
      font-size: 22px;
    }
    @include wide {
      font-size: 24px;
    }
  }

  .btn {
    width: 100%;
    @include tablet {
      width: auto;
    }
  }
}

.strapline {
  background-color: rgba($blue-dark, 0.9);
  position: absolute;
  text-align: center;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 30px 0;
  @include laptop {
    padding: 40px 0;
  }

  p {
    margin: 0 auto;
    font-size: 17px;
    color: $gray-text;
    line-height: 1.4;
    max-width: 1200px;
    @include tablet {
      line-height: 1.6;
      font-size: 18px;
    }
    @include laptop {
      font-size: 19px;
    }
    @include desktop {
      font-size: 20px;
    }
    @include wide {
      font-size: 21px;
    }
  }
}



.identity-panel {
  text-align: center;
  background-color: $gray;

  .panel-header {
    padding-bottom: 30px;
    @include desktop {
      padding-bottom: 40px;
    }
  }

  .identity-row {
    margin-bottom: -30px;
  }

  .btn {
    margin-top: 30px;
    @include desktop {
      margin-top: 40px;
    }
  }
}


.identity-col {
  margin-bottom: 30px;

  .icon {
    width: 144px;
    height: 144px;
    padding: 20px;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: $red;
    box-shadow: 0px 3px 7px 0px rgba($blue-dark, 1);
    position: relative;

    &:before {
      content: '';
      @include stretch(-5px);
      border: 5px solid $white;
      border-radius: 50%;
    }
  }

  .content {
    max-width: 325px;
    margin: 0 auto;
  }

  h3 {
    text-transform: uppercase;
    margin: 32px 0 20px;
    padding-bottom: 7px;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      border: 1px solid $pink;
      transform: translateX(-50%);
      width: 180px;
      top: 100%;
      left: 50%;
    }
  }

}



.service-panel {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow: hidden;
  padding: 50px 0;
  border-bottom: 10px solid $gray;
  position: relative;
  @include tablet {
    padding: 100px 0;
  }
  @include laptop {
    padding: 120px 0;
  }
  @include desktop {
    padding: 140px 0;
  }
  @include wide {
    padding: 160px 0;
  }

  &:before {
    content: '';
    background-color: rgba($gray-dark, 0.6);
    transform: skew($angle);
    position: absolute;
    z-index: 101;
    top: 0;
    bottom: 0;
    left: -15%;
    width: 60%;
    @include tablet {
      left: -10%;
      width: 50%;
    }
  }

  &:after {
    content: '';
    position: absolute;
    @include stretch;
    z-index: 1;
    background-color: rgba($blue-darker, 0.4);
  }

  &:nth-of-type(even) {
    &:before {
      left: auto;
      right: -15%;
      @include tablet {
        right: -10%;
      }
    }

    @include tablet {


      .row {
        justify-content: flex-end;
      }

      .btn {
        &:before {
          left: auto;
          right: -16px;
        }

        &:hover, &:focus {
          &:before {
            right: 0;
          }
        }
      }
    }
  }

  .service-box {
    z-index: 300;
    @include tablet {
      margin: 0 24px;
    }

    p {
      max-width: 645px;
    }

    .btn-container {
      width: 100%;
      @include tablet {
        left: auto;
        width: 524px;

        text-align: left;
        right: 50px;
      }
      @include laptop {
      }
    }
  }
}

.equipment-panel {
  background-image: url(../images/bkg-restaurant-equipment.jpg);
}

.refrigeration-panel {
  background-image: url(../images/bkg-commercial-refrigeration.jpg);
}

.sinks-panel {
  background-image: url(../images/bkg-restaurant-sinks.jpg);
}

.smallwares-panel {
  background-image: url(../images/bkg-smallwares.jpg);
}


.testimonials-panel {
  text-align: center;
  background-color: $gray;

  h2 {
    margin: 0 0 35px;
    @include tablet {
      margin: 0 0 45px;
    }
  }

  .testimonial-carousel {
    margin: 0 -15px 15px;
    @include tablet {
      margin: 0 -15px 30px;
    }
    @include desktop {
      margin: 0 -25px 40px;
    }
    @include hd {
      margin: 0 -70px 40px;
    }
  }

  .slick-slide {
    margin: 0 15px;
    @include tablet {
      margin: 0 15px;
    }
    @include desktop {
      margin: 0 25px;
    }
    @include hd {
      margin: 0 70px;
    }

    .fa-quote-right {
      color: $red-light;
      font-size: 45px;
      margin-bottom: 20px;
      @include tablet {
        font-size: 55px;
      }
    }

    .quote {
      margin: 0;
    }

    .quote-border {
      border: 1px solid $pink;
      margin: 18px;
    }

    .author {
      font-weight: 500;
    }
  }

  .slider-nav {
    position: relative;
    display: inline-block;
    margin-bottom: 10px;
  }

  .slick-arrow {
    height: 32px;
    top: 65%;

    &:before {
      font-family: FontAwesome;
      color: $blue-dark;
      font-size: 24px;
      opacity: 1;
    }

    &.slick-prev {
      left: -25px;

      &:before {
        content: '\f053';
      }
    }

    &.slick-next {
      right: -25px;

      &:before {
        content: '\f054';
      }
    }
  }

  .slick-dots {
    position: static;
    width: auto;

    li {
      margin: 0 6px;

      button {
        &:before {
          content: '\f10c';
          font-family: FontAwesome;
          font-size: 24px;
          color: $blue-dark;
          opacity: 1;
        }
      }

      &.slick-active button::before {
        content: '\f111';
        color: $red;
      }
    }
  }
}
