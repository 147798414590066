#modal {
  overflow: scroll;
  background-color: rgba($black, 0.5);

  .modal-title {
    color: $brand-primary;
  }

  .modal-dialog {
    @include tablet {
      max-width: 50%;
    }
  }

  .btn-close {
    background-color: $brand-primary;
    color: white;
    border-radius: 0;

    &:hover {
      background-color: darken($brand-primary, 10%);
    }
  }
}

.has-hero header.banner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

header.banner {
  background-color: rgba($white, 0.9);
  border-bottom: 20px solid $red;
  z-index: 1001;
  @include laptop {
    border-bottom: none;
  }

  .brand {
    display: block;
    position: absolute;
    left: 0;
    top: 2px;
    width: 170px;
    @include desktop {
      width: 220px;
    }
    @include wide {
      width: auto;
    }
  }

  .header-only {
    display: block;
  }

  .hide-header {
    display: none;
  }

  .nav {
    justify-content: flex-end;

    > li {
      position: relative;
      margin: 0 1px;
      @include wide {
        margin: 0 2px;
      }

      > a {
        display: block;
        font-size: 13px;
        position: relative;
        z-index: 99;
        @include desktop {
          font-size: 15px;
        }
        @include hd {
          font-size: 17px;
        }
      }

      &.phone {
        a:before {
          content: '\f095';
          font-family: FontAwesome;
          padding-right: 4px;
        }
      }

      &.menu-item-has-children > a:after {
        content: '\f0d7';
        font-family: FontAwesome;
        padding-left: 5px;
      }
    }
  }

  .header-top {
    position: relative;
    padding-top: 20px;
    padding-bottom: 5px;
    display: flex;
    justify-content: flex-end;
    @include laptop {
      padding-top: 38px;
      padding-bottom: 13px;
    }
    @include desktop {
      padding-top: 48px;
      padding-bottom: 25px;
    }
    @include wide {
      padding-top: 54px;
    }

    .nav {
      @include padding-offset(8px, li);
      @include wide {
        @include padding-offset(15px, li);
      }
      @include hd {
        @include padding-offset(18px, li);
      }

      li {
        a {
          color: $black;
          border-bottom: 1px solid transparent;

          &:hover, &:focus {
            border-bottom: 1px solid $black;
          }
        }

        &.active a {
          border-bottom: 1px solid $black;
        }

        &.phone a {
          &:hover, &:focus {
            cursor: default;
            background-color: transparent;
          }
        }

        &.menu-item-has-children {

          &:hover, &:focus {
            .sub-menu {
              visibility: visible;
              opacity: 1;
            }
          }

          .sub-menu {
            left: auto;
            background-color: $gray;
            box-shadow: 0px 1px 5px -3px rgba($black, .6);

            li {
              padding: 0;
            }

            a {
              color: $black;
              border: 0;

              &:hover, &:focus {
                background-color: darken($brand-primary, 10);
                color: $white;
              }
            }
          }
        }
      }
    }
  }

  .nav-banner {
    background-color: $red;

    .nav {
      @include padding-offset(6px, '> li > a');
      @include desktop {
        @include padding-offset(10px, '> li > a');
      }
      @include wide {
        @include padding-offset(22px, '> li > a');
      }

      > li {
        > a {
          color: $white;
          padding-top: 14px;
          padding-bottom: 12px;
          @include desktop {
            padding-top: 17px;
            padding-bottom: 15px;
          }
        }

        &:before,
        &:after {
          content: '';
          position: absolute;
          transition: $base-transition;
          background-color: rgba($gray-darker, 0.3);
          z-index: 1;
          left: 0;
          right: 0;
          height: 0;
        }

        &:before {
          top: 0;
        }

        &:after {
          bottom: 0;
        }

        &:hover, &:focus, &.open, &.active {
          &:before,
          &:after {
            height: 50%;
          }
        }

        &:hover, &:focus {
          .sub-menu {
            visibility: visible;
            opacity: 1;
          }
        }

        .sub-menu {
          background-color: $brand-primary;
          padding: 4px 0;

          a {
            color: $white;

            &:hover, &:focus {
              background-color: darken($brand-primary, 10);
              color: $white;
            }
          }

          .active a {
            background-color: darken($brand-primary, 10);
            color: $white;
          }
        }
      }
    }
  }

  .sub-menu {
    position: absolute;
    left: 0;
    z-index: 1000;
    opacity: 0;
    visibility: hidden;
    will-change: opacity;
    transition: $base-transition;
    top: 100%;
    min-width: 100%;

    a {
      border: none;
      font-size: $body-font-size;
      white-space: nowrap;
      display: block;
      margin: 0 0 1px;
      padding: 6px 7px;
      font-size: 14px;
      @include desktop {
        padding: 8px 20px;
        font-size: 15px;
      }
      @include wide {
        font-size: 16px;
        padding: 8px 30px;
      }
    }

    &.open {
      visibility: visible;
      opacity: 1;
    }
  }
}
