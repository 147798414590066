aside.sidebar {
  margin-top: 20px;
  @include laptop {
    margin-top: 0;
  }

  .page-listing,
  .widget {
    box-shadow: 0px 0px 4px 2px rgba($black, 0.1);
    background-color: $white;
    padding: 20px 20px 5px;
    margin-bottom: 20px;

    &:last-of-type {
      margin-bottom: 0;
    }

    h3 {
      margin: 0;
      text-transform: uppercase;
      border-bottom: 2px solid $pink;
      padding-bottom: 7px;
      color: $blue-dark;
      display: block;

      a {
        color: $blue-dark;

        &:focus,
        &:hover {
          color: $red;
        }
      }
    }

    ul {
      list-style: none;
      padding: 8px 0;
      margin: 0;

      li {
        a {
          display: block;
          padding: 6px 0;
          color: $blue-dark;

          &:focus,
          &:hover {
            color: lighten($blue-darker, 15);
            padding-left: 8px;
          }
        }

        &.current_page_item a {
          color: lighten($blue-darker, 15);
        }
      }
    }
  }
}
