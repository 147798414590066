.post {
  h1 {
    margin: 0 0 10px;
    border-bottom: 2px solid #e96656;
    padding-bottom: 7px;
  }

  h2 a {
    color: $blue-dark;
    display: block;
    border-bottom: 2px solid #e96656;
    padding-bottom: 7px;
  }

  .entry-meta {
    font-style: italic;
    font-weight: 500;
  }

  .post-thumbnail {
    margin-bottom: 15px;
  }
}

.post-preview {
  margin-bottom: 30px;

  .entry-meta {
    margin-bottom: 10px;
  }

  .btn {
    margin-left: 20px;
  }
}


.products {

  h1 {
    margin-bottom: 20px;
  }

  .product-price {
    font-size: 24px;
    font-weight: 500;
  }

  footer {
    margin-bottom: 30px;
  }

  .lslide {
    @include aspect-ratio(4,3);
    background-size: cover;
    background-repeat: no-repeat;
    cursor: pointer;

    &:after {
      font-family: FontAwesome;
      font-size: 24px;
      content: '\f00e';
      @include stretch;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba($black, .5);
      color: $white;
      opacity: 0;
      transition: $base-transition;
    }

    &:hover:after {
      opacity: 1;
    }

    img {
      display: none;
    }
  }
}


.breadcrumbs {
  @extend .card;
  display: block;
  padding: 15px 20px;
  margin: 0 0 30px;
  font-size: 14px;
  font-weight: 400;

  @include phablet {
    font-size: 15px;
  }

  a {
    border: 0;
    color: $black;

    &:hover {
      color: $brand-primary;
    }
  }

  span[property="itemListElement"] {
    margin: 0 3px;

    @include phablet {
      margin: 0 5px;
    }
  }
}
