// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}

.gform_wrapper {
  ul {
    list-style: none;
    padding: 0;
  }

  .gfield_description {
    margin-bottom: 5px;
  }

  .gform_fields {
    display: flex;
    flex-wrap: wrap;
    @include padding-offset(10px, '.gfield');

    .gfield {
      margin-bottom: 15px;
      @include flex(0,0,100%);

      &.half_field {

        @include tablet {
          @include flex(0,0,50%);
        }
      }

      .ginput_complex {
        display: flex;
        flex-wrap: wrap;
        @include padding-offset(10px, 'span');

        span {
          @include flex(0,0,100%);
          margin-bottom: 5px;
        }

        .ginput_left, .ginput_right {

          @include tablet {
            @include flex(0,0,calc(100% / 3));
          }
        }
      }

      .gfield_radio {
        display: flex;
        flex-wrap: wrap;
        @include padding-offset(10px, 'li');

        li {
          text-align: center;
        }
      }

      .ginput_container {

        label {
          font-size: 15px;
          font-weight: 500;
        }
      }

      label {
        font-weight: 800;
      }

      input,
      textarea {
        background-color: $gray;
        border: none;
        padding: 10px;
        width: 100%;
        font-size: 16px;
      }

      select {
        padding: 10px;
        width: 100%;
        border: 1px solid #ccc;
        box-shadow: none;
        background: transparent;
        background-image: none;
        -webkit-appearance: none;

        &:focus {
          outline: none;
        }
      }

      textarea {
        height: 180px;
      }
    }
  }

  .gform_footer {

    input[type=submit] {
      -webkit-appearance: none;
      -moz-appearance: none;
      @extend .btn;
      @extend .btn-primary;
      cursor: pointer;
      background-color: $brand-primary;
      font-style: normal;

      &:hover, &:focus {
        background-color: darken($red, 10);
      }
    }
  }

  .g-recaptcha label {
    @extend .sr-only;
  }
}
