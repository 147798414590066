html {
  display: flex;
}

body.sideNavBody { // any body class found on every page can be used here, this keeps styles from effecting the Wordpress editor
  min-height: 100vh;
  flex: 1 1 auto;
  display: flex;
  flex-flow: column nowrap;
}

.wrap {
  flex: 1 1 auto;
}

html, body {
  font-weight: $body-font-weight;
  font-family: $body-font;
  color: $body-color;
  @include smooth-fonts;
  line-height: 1.2;
  font-size: $body-font-size;
  @include tablet {
    font-size: ($body-font-size + 2px);
  }
}

img {
  max-width: 100%;
  height: auto;
}

h1, .h1 {
  color: $heading-color;
  font-family: $heading-font;
  font-weight: $h1-weight;
  font-size: $h1-size;
  text-transform: uppercase;
  @include laptop {
    font-size: ($h1-size + 2px);
  }
  @include desktop {
    font-size: ($h1-size + 6px);
  }
  @include wide {
    font-size: ($h1-size + 8px);
  }
}

h2, .h2 {
  color: $heading-color;
  font-family: $heading-font;
  font-weight: $h2-weight;
  font-size: $h2-size;
  @include tablet {
    font-size: ($h2-size + 4px);
  }
  @include desktop {
    font-size: ($h2-size + 8px);
  }
}

h3, .h3 {
  color: $heading-color;
  font-family: $heading-font;
  font-weight: $h3-weight;
  font-size: $h3-size;
  @include tablet {
    font-size: ($h3-size + 2px);
  }
}

p, li {
  font-family: $body-font;
  font-weight: $body-font-weight;
  line-height: 1.4;
  font-size: $body-font-size;
  @include tablet {
    font-size: ($body-font-size + 2px);
  }
  @include laptop {
    font-size: $body-font-size;
  }
  @include desktop {
    font-size: ($body-font-size + 2px);
  }
}

a {
  transition: $base-transition;
  &:hover, &:focus {
    text-decoration: none;
  }
}

ul.nav {
  list-style-type: none;
  padding: 0;

  .sub-menu {
    list-style-type: none;
    padding: 0;
  }
}

.panel {
  padding: $section-padding 0;
  @include tablet {
    padding: ($section-padding + 15px) 0;
  }
  @include laptop {
    padding: ($section-padding + 25px) 0;
  }
}

.header-only,
.mobile-only {
  display: none;
}


.angled-box {
  padding: 20px;
  position: relative;
  @include tablet {
    padding: 30px 40px;
    margin: 0 35px;
  }
  @include laptop {
    padding: 30px 50px;
  }
  @include desktop {
    padding: 30px 60px;
  }

  &.has-btn {
    @include tablet {
      padding-bottom: 35px;
    }

    .btn-container {

      @include tablet {
        position: absolute;
        top: 100%;
        left: 40px;
        width: 100%;
        transform: translateY(-50%);
        z-index: 200;
      }

    }

    .btn {
      position: relative;
      z-index: 200;
      margin-bottom: 5px;

      @include tablet {
        margin-right: 30px;
      }
    }
  }

  .content {
    position: relative;
    z-index: 200;

    h1, .h1,
    h2, .h2,
    h3, .h3,
    li, p {
      color: $white;
    }
  }

  &:before,
  &:after {
    position: absolute;
    background-color: rgba($gray-dark, 0.9);
    z-index: 1;
    @include tablet {
      transform: skew($angle);
    }
  }

  &:before {
    @include tablet {
      content: '';
      top: 0;
      left: -16px;
      bottom: 0;
      width: 10px;
    }
  }

  &:after {
    content: '';
    @include stretch;
  }

  &.red-box {
    &:before,
    &:after {
      background-color: rgba($red, 0.9);
    }
  }
}

.lSAction>a {
   background-image: url("../images/controls.png");
}

.lg-outer .lg-has-vimeo .lg-video-play {
  background: url("../images/vimeo-play.png") no-repeat scroll 0 0 transparent;
}
.lg-outer .lg-has-vimeo:hover .lg-video-play {
  background: url("../images/vimeo-play.png") no-repeat scroll 0 -58px transparent;
}
.lg-outer .lg-has-html5 .lg-video-play {
  background: transparent url("../images/video-play.png") no-repeat scroll 0 0;
}
.lg-outer .lg-has-youtube .lg-video-play {
  background: url("../images/youtube-play.png") no-repeat scroll 0 0 transparent;
}
.lg-outer .lg-has-youtube:hover .lg-video-play {
  background: url("../images/youtube-play.png") no-repeat scroll 0 -60px transparent;
}
.lg-outer .lg-item {
  background: url("../images/loading.gif") no-repeat scroll center center transparent;
}
