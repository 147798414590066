// Colors
$gray-100:              #f8f9fa;
$gray-600:              #868e96;
$gray-800:              #343a40;
$yellow:                #ffc107;
$green:                 #28a745;
$cyan:                  #17a2b8;

$red:                   #a81e23;
$red-light:             #ce240f;
$pink:                  #e96656;

$brand-primary:         $red;
$blue-dark:             #263845;
$blue-darker:           #092741;
$black:                 #000000;
$gray-darker:           #131c23;
$gray-dark:             #212121;
$gray-text:             #dedede;
$gray:                  #efefef;
$white:                 #ffffff;

$theme-colors: (
  primary:              $brand-primary,
  secondary:            $blue-dark,
  success:              $green,
  info:                 $cyan,
  warning:              $yellow,
  danger:               $red,
  light:                $gray-100,
  dark:                 $gray-800
);


// Fonts
$primary-font:          'Fira Sans', sans-serif;
// Regular              400
// Medium               500
// Heavy                900
$body-font-size:        16px;
$body-font-weight:      400;
$body-font:             $primary-font;
$body-color:            #000000;
$heading-font:          $primary-font;
$heading-color:         $blue-dark;
$h1-weight:             900;
$h1-size:               32px;
$h2-weight:             500;
$h2-size:               20px;
$h3-weight:             500;
$h3-size:               18px;

// Base Styles
$base-transition:       0.3s ease-in-out;
$section-padding:       30px;
$angle:                 -12deg;

$slick-font-path: "../fonts/";
$slick-loader-path: "../images/";

//Side-Nav
$sideNavWidth: 275px;
$sideNavDirection: (right);

$button_width: 45px;     // width of button area
$button_height: 60px;     // height of button area
$bar_thickness: 6px;     // thickness of button bars
$bar_roundness: 6px;
$button_pad: 0px;      // left/right padding between button area and bars.
$button_bar_space: 8px;     // spacing between button bars
$button_transistion_duration: 0.3s;  // transition duration
$hamburger_normal: $red;
$hamburger_active: $red;

// Bootstrap Variables
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  wd: 1440px,
  hd: 1600px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1170px,
  wd: 1400px,
  hd: 1560px
);

// Breakpoint variables
$width_mobile:          480px;
$width_phablet:         576px;
$width_tablet:          768px;
$width_laptop:          992px;
$width_desktop:         1200px;
$width_wide:            1440px;
$width_hd:              1600px;

/*
 * Mixins
 */
// Media query mixins
@mixin mobile { @media (min-width: #{$width_mobile}) { @content; } }
@mixin phablet { @media (min-width: #{$width_phablet}) { @content; } }
@mixin tablet { @media (min-width: #{$width_tablet}) { @content; } }
@mixin laptop { @media (min-width: #{$width_laptop}) { @content; } }
@mixin desktop { @media (min-width: #{$width_desktop}) { @content; } }
@mixin wide { @media (min-width: #{$width_wide}) { @content; } }
@mixin hd { @media (min-width: #{$width_hd}) { @content; } }

@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

@mixin stretch($offset: 0) {
  position: absolute;
  top: #{$offset};
  right: #{$offset};
  left: #{$offset};
  bottom: #{$offset};
  z-index: 99;
}

@mixin smooth-fonts() {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin letter-spacing($letter-spacing: 0) {
  letter-spacing: calc(#{$letter-spacing} * 0.001em);
}

// Flexbox mixin - prevents most IE 11 issues
@mixin flex($grow: 0, $shrink: 0, $basis: 100%) {
  flex: #{$grow} #{$shrink} #{$basis};
  max-width: #{$basis};
  width: #{$basis};
}

// Padding Offset Mixin
@mixin padding-offset($padding: 0, $selector: '') {
  margin-left: -#{$padding};
  margin-right: -#{$padding};
  #{$selector} {
    padding-left: #{$padding};
    padding-right: #{$padding};
  }
}
