.btn {
  padding: 16px 15px 12px;
  position: relative;
  background-color: transparent;
  border-radius: 0;
  font-weight: 500;
  font-size: 17px;
  line-height: 1;
  text-transform: uppercase;
  outline: 0!important;
  box-shadow: none!important;
  border: none;
  white-space: normal;
  @include tablet {
    font-style: italic;
    white-space: nowrap;
    font-size: 19px;
    padding-left: 30px;
    padding-right: 30px;
  }

  > span {
    position: relative;
    z-index: 200;
  }

  &.btn-border {
    &:before {
      @include tablet {
        content: '';
        top: 0;
        left: -16px;
        bottom: 0;
        width: 10px;
      }
    }
  }

  &:before,
  &:after {
    transition: $base-transition;
    position: absolute;
    background-color: rgba($blue-dark, 0.9);
    z-index: 1;
    @include tablet {
      transform: skew($angle);
    }
  }

  &:after {
    content: '';
    @include stretch;
  }

  &:hover, &:focus {
    &:before {
      left: 0;
    }
  }

  &.btn-primary {
    background-color: transparent;
    &:before, &:after {
      background-color: $red;
    }

    &:hover, &:focus {
      &:before, &:after {
        background-color: darken($red, 10);
      }
    }
  }

  &.btn-secondary {
    background-color: transparent;
    &:before, &:after {
      background-color: $blue-dark;
    }

    &:hover, &:focus {
      &:before, &:after {
        background-color: darken($blue-dark, 10);
      }
    }
  }
}
