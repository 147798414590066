.sideNav {
  width: $sideNavWidth;
  #{$sideNavDirection}: -$sideNavWidth;
  position: fixed;
  z-index: 1000;
  height: 100%;
  top: 0;
  background: $blue-dark;
  transition: all 0.3s ease;
  overflow-y: auto;

  .nav {
    padding-top: 8px;
  }

  .menu-item-has-children {
    > a:after {
      content: '\f0d9';
      font-family: FontAwesome;
      padding-left: 5px;
      margin-top: -3px;
    }

    &.open > a:after {
      content: '\f0d7';
    }

    .sub-menu {
      
      a {
        font-size: 14px;
      }
    }
  }

  a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    font-weight: 500;
    font-size: 15px;
    // border-bottom: 1px solid #111;
    padding: 10px 20px;
    @include tablet {
      padding: 12px 20px;
    }
  }

  .mobile-only {
    display: block;
  }

  &.sideNav-open {
    #{$sideNavDirection}: 0px;
    @include laptop {
      #{$sideNavDirection}: -$sideNavWidth;
    }
  }

  .sub-menu {
    background-color: darken($blue-dark, 10);
    display: none;
    top: 100%;
    min-width: 100%;

    a {
      color: $white;
      border: none;
      font-size: $body-font-size;
      white-space: nowrap;
      display: block;
      margin: 0;

      &:hover, &:focus {
        background-color: darken($blue-dark, 10);
        color: $white;
      }
    }

    &.open {
      display: block;
    }
  }
}

/* classes applied to the body */
.sideNavBody {
  overflow-x: hidden;
  position: relative;
  #{$sideNavDirection}: 0;
  transition: all 0.3s ease;
}

.sideNavBody-push {
  #{$sideNavDirection}: $sideNavWidth;
  @include laptop {
    #{$sideNavDirection}: 0;
  }
}
