.footer-cta-panel {
  background-image: url(../images/bkg-footer-cta.jpg);
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  padding: 60px 0;
  @include tablet {
    padding: 120px 0;
  }
  @include laptop {
    padding: 140px 0;
  }
  @include wide {
    padding: 180px 0;
  }

  .cta-content .vp-sub {
    max-width: 600px;
  }
}


.footer-content {
  background-color: $blue-dark;
  overflow: hidden;
  padding: 35px 0;
  text-align: center;
  @include tablet {
    text-align: left;
  }

  p, li, a {
    color: $white;
    line-height: 1.6;
    white-space: nowrap;
    font-size: 16px;
    @include desktop {
      font-size: 15px;
    }
    @include wide {
      font-size: 16px;
    }
  }

  .nav li a {
    white-space: nowrap;
  }

  .footer-row {
    justify-content: space-between;
    flex-wrap: wrap;
    @include desktop {
      flex-wrap: nowrap;
    }
  }

  .col {
    @media screen and (max-width: $width_tablet - 1px) {
      @include flex(0, 0, 100%);
    }
  }

  .address-col {
    margin-top: 25px;
  }

  .address-col,
  .hours-col,
  .nav-col {
    @include tablet {
      margin-top: 18px;
    }
    @include laptop {
      margin-top: 25px;
    }
    @include desktop {
      margin-top: 15px;
    }
    @include wide {
      margin-top: 25px;
    }
  }

  .address {
    @include wide {
      margin-left: 20px;
    }
    @include hd {
      margin-left: 0;
    }
  }

  .footer-nav {
    .nav {
      display: block;
      @include tablet {
        columns: 3;
        column-gap: 30px;
      }
      @include desktop {
        margin-right: -110px;
      }
      @include wide {
        column-gap: 70px;
      }

      .menu-item a {
        border: 1px solid transparent;

        &:hover {
          border-bottom: 1px solid $white;
        }
      }
    }

    .sub-menu {
      display: none;
    }
  }
}


.copyright {
  background-color: #203341;
  text-align: center;
  padding: 9px 0;

  .copyright-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    @include laptop {
      justify-content: space-between;
    }

    div, a {
      color: $gray;
      font-size: 12px;
    }
  }
}
